<template>
  <div>
    <div class="bg-w-silver">
      <div class="bg-orange">
        <div class="text-title --white text-center font-weight-bold pt-3 pb-3">
          {{
            change ?
              $t("reservationAndUsageHistory.changeRentalListingChange") :
              $t("reservationAndUsageHistory.changeRentalListing")
          }}
        </div>
      </div>
      <!--  -->
      <div class="px-4 mb-4">
        <v-radio-group
          hide-details
          v-model="borrow"
          @change="toggleBorrow"
        >
          <v-container class="mb-2">
            <v-row>
              <v-col
                class="radio-border d-flex py-0 mr-1 pr-0"
                v-for="item in firstRadio"
                :key="item.id"
              >
                <v-radio
                  class="py-2 pl-0 pr-1 px-0"
                  :label="item.label"
                  :value="item.value"
                ></v-radio>
              </v-col>
              <v-col class="col-2 px-0 d-flex flex-column justify-center">
                <span class="text-small text-center --white px-0 bg-pink rounded-4 py-1 mx-2">
                  {{ $t("reservationAndUsageHistory.mandatory") }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-radio-group>

        <template v-if="borrow">
          <FormLabel :label="$t('reservationAndUsageHistory.numberOfRentalListingGroups')" required/>

          <v-container>
            <v-row>
              <v-col class="text-medium col-5 --bg px-0 py-1 mb-3">
                <v-select
                  hide-details
                  solo
                  flat
                  outlined
                  class="rounded-lg"
                  :items="items"
                  v-model="itemCount"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>

          <div v-for="(item, ind) in value" :key="ind">
            <div class="bg-smoke text-medium font-weight-bold --ms py-2 pl-2 mt-4">
              {{ $t("reservationAndUsageHistory.rentalExhibitionGroup") }}{{ ind + 1 }}
            </div>

            <v-radio-group
              hide-details
              :value="
                typeof rentalType[ind] === 'undefined'
                  ? getDefaultRentalPeriod(ind)
                  : rentalType[ind]
                "
              @change="v => setRentalPeriod(ind, v)"
            >
              <v-container>
                <v-row>
                  <v-col
                    class="radio-border d-flex justify-center flex-column  py-0 mr-1 pr-0"
                    v-for="item in secondRadio"
                    :key="item.id"
                  >
                    <v-radio
                      class="py-2 pl-0 pr-0 px-0"
                      :label="item.label"
                      :value="item.value"
                    />
                  </v-col>
                  <v-col class="col-2 px-0">
                    <span class="text-medium  col-2 text-center --white px-0 bg-pink rounded-4 py-1 px-2">
                      {{ $t("reservationAndUsageHistory.mandatory") }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-radio-group>

            <v-main class="mt-3">
              <FormLabel :label="$t('reservationAndUsageHistory.lendingSchedule')" required />
              <div class="d-flex align-center">
                <v-select
                  :disabled="shouldDateSelectDisabled(ind)"
                  :value="getFormattedDate(item.fromDate)"
                  :items="[getFormattedDate(item.fromDate)]"
                  @click="openFromDialog(ind)"
                  solo
                  flat
                  outlined
                  class="rounded-lg"
                  hide-details
                />
                <ModalDated
                  :visible="rentalDateModalsFrom[ind]"
                  @close="openFromDialog(ind, false)"
                  :items="fromDateRange"
                  :value="item.fromDate"
                  @input="v => changeRentalItem(ind, { from: v })"
                />
                <div class="text-medium --prm --w-bold px-4"> 〜 </div>
                <v-select
                  :disabled="!item.fromDate || shouldDateSelectDisabled(ind)"
                  :value="getFormattedDate(item.toDate)"
                  :items="[getFormattedDate(item.toDate)]"
                  @click="openToDialog(ind)"
                  solo
                  flat
                  outlined
                  class="rounded-lg"
                  hide-details
                />
                <ModalDated
                  :visible="rentalDateModalsTo[ind]"
                  @close="openToDialog(ind, false)"
                  :items="getToDateRange(ind)"
                  :value="item.toDate"
                  @input="v => changeRentalItem(ind, { to: v })"
                />
              </div>
            </v-main>

            <v-main class="mt-3 mb-6">
              <FormLabel :label="$t('reservationAndUsageHistory.rentalListing')" required />
              <div @click="openItemSelect(ind)">
                <v-select
                  :value="getSelectedItemName(ind)"
                  :items="getSelectedItemName(ind) ? [getSelectedItemName(ind)] : []"
                  solo
                  flat
                  outlined
                  class="rounded-lg"
                  hide-details
                />
              </div>
            </v-main>
          </div>

          <v-divider></v-divider>
          <v-container class="mt-3 pb-9">
            <v-row>
              <v-col class="text-title d-flex flex-column justify-center px-0 py-1">
                <span>
                  {{ $t("reservationAndUsageHistory.totalRentalAmount") }}
                </span>
              </v-col>
              <v-col class="--prm font-weight-bold  text-right --bg px-0 py-1">
                <span class="text-large">{{ totalPrice }}</span>
                <span class="text-title">
                  {{ $t("reservationAndUsageHistory.yen") }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </div>
    </div>
    <ModalRentalListing
      :visible="isOpen"
      :checkInDate="checkInDate"
      :checkOutDate="checkOutDate"
      :facilityId="facilityId"
      :value="selectedGroup"
      :originalValue="originalRental"
      @close="isOpen = false"
      @select="v => selectRentalItem(v)"
    />
    <v-btn
      elevation="0"
      v-if="btn"
      rounded
      block
      height="66"
      class="text-title --white mb-3"
      color="bg-orange"
    >
      {{ $t("reservationAndUsageHistory.changeToTheAboveContent") }}
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue'
import FormLabel from '../Inputs/FormLabel.vue'
import ModalDated from './modal/ModalDated'
import ModalRentalListing from './modal/ModalRentalListing'
import { calculateRentalItemPrice } from '@/utils/prices'
import { addDays, getDateRangeArray, getStayDateRangeArray } from '@/utils/get-date'
import { numberArrayGenerator } from '@/utils/generator'

export default {
  name: 'ChangeRentalListing',
  components: {
    ModalDated,
    ModalRentalListing,
    FormLabel
  },
  props: {
    checkInDate: String,
    checkOutDate: String,
    facilityId: Number,
    forChange: Boolean,
    btn: {
      Boolean
    },
    value: {
      type: Array,
      default: () => []
    },
    change: Boolean
  },
  data () {
    return {
      isOpen: false,
      borrow: !!this.value?.length,
      itemInd: null,
      rentalType: [],
      firstRadio: [
        { label: this.$t('reservationAndUsageHistory.borrowRentalListing'), value: true },
        { label: this.$t('reservationAndUsageHistory.noRentalListingRequired'), value: false }
      ],
      secondRadio: [
        { label: this.$t('reservationAndUsageHistory.usedForAllDates'), value: 'whole' },
        { label: this.$t('reservationAndUsageHistory.useOnlyForSpecificDates'), value: 'part' }
      ],
      rentalDateModalsFrom: [],
      rentalDateModalsTo: [],
      selectedItems: [],
      originalValue: []
    }
  },
  computed: {
    itemCount: {
      get () { return this.value.length },
      set (newLength) {
        if (this.value.length < newLength) {
          this.emitInput(
            this.value.concat([...Array(newLength - this.value.length)].map(() => ({})))
          )
        } else {
          this.emitInput(
            this.value.slice(0, newLength)
          )
        }
      }
    },
    totalPrice () {
      try {
        const result = calculateRentalItemPrice(this.value)
        return this.$options.filters.toThousands(result.totalPrice)
      } catch (e) {
        return '----'
      }
    },
    fromDateRange () {
      return getStayDateRangeArray(this.checkInDate, this.checkOutDate)
    },
    selectedGroup () {
      if (typeof this.itemInd === 'number') { return this.value[this.itemInd]?.selects ?? [] }
      return []
    },
    originalRental () {
      if (typeof this.itemInd === 'number') { return this.originalValue[this.itemInd] }
      return []
    },
    items () {
      return numberArrayGenerator(20, false)
    }
  },
  methods: {
    getFormattedDate (date) {
      if (!date) return ''
      return this.$options.filters.jaShortDate(date)
    },
    openFromDialog (ind, visible = true) {
      this.$set(this.rentalDateModalsFrom, ind, visible)
    },
    openToDialog (ind, visible = true) {
      this.$set(this.rentalDateModalsTo, ind, visible)
    },
    emitInput (arr) {
      this.$emit('input', arr)
    },
    changeRentalItem (ind, {
      from, to, selects
    }) {
      const copied = this.value.slice()
      const item = copied[ind]
      if (from) { item.fromDate = from }
      if (to) { item.toDate = to }
      if (selects) { item.selects = selects }

      if (item.fromDate && item.toDate && item.toDate <= item.fromDate) {
        // from date cannot be the same or after toDate. Reset todate to prompt user to re-select
        item.toDate = null
      }
      copied.splice(ind, 1, item)
      this.emitInput(copied)
    },
    setRentalPeriod (ind, newType) {
      if (newType === 'whole') {
        this.changeRentalItem(ind, {
          from: this.checkInDate,
          to: this.checkOutDate
        })
      }
      Vue.set(this.rentalType, ind, newType)
    },
    getSelectedItemName (ind) {
      const { selects } = this.value[ind]
      if (selects) return selects.map(s => s.rentalItemDef.name).join(', ')
      return null
    },
    openItemSelect (ind) {
      this.itemInd = ind
      this.isOpen = true
    },
    selectRentalItem (selects) {
      this.changeRentalItem(this.itemInd, { selects })
      this.isOpen = false
    },
    toggleBorrow (borrow) {
      if (!borrow) {
        this.emitInput([])
      }
    },
    getDefaultRentalPeriod (ind) {
      if (this.forChange) {
        if (this.value[ind]) {
          const { fromDate, toDate } = this.value[ind]
          if (fromDate === this.checkInDate && toDate === this.checkOutDate) {
            Vue.set(this.rentalType, ind, 'whole')
            return 'whole'
          }
        }
        return 'part'
      }
      return null
    },
    getToDateRange (ind) {
      const { fromDate } = this.value[ind]
      return getDateRangeArray(addDays(fromDate, 1), this.checkOutDate, { excludeLast: false })
    },
    shouldDateSelectDisabled (ind) {
      return this.rentalType[ind] === 'whole'
    }
  },
  mounted () {
    this.originalValue = this.value.map(v => v.selects ?? [])
  }
}
</script>

<style lang="scss" scoped>
@use '/src/assets/styles/modules/variables-scss' as v;

::v-deep {
  .v-radio {
    label {
      font-size: var(--font-size__medium);
      color: var(--color__x-dark);
    }
  }
  .rounded-10 {
    border: solid 1px var(--color__primary);
  }
  @media (max-width: v.$breakpoint__x-small - 1) {
    .rounded-10 {
      padding: 12px !important;
    }
  }
}
</style>
